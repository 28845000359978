.wj-flexgrid {
    /* height: 700px; */
    margin: 6px 0;
    
  }

  .input-cell {
    /* border-right: rgb(130, 214, 130) 5px solid; */
    background-color: #ffe9bc;
  }

  .precon-cell {
    /* border-right: rgb(130, 214, 130) 5px solid; */
    background-color: #a9d08e;
  }

  .construction-cell {
    /* border-right: rgb(0, 110, 255) 5px solid; */
    background-color: #9bc2e6;
  }

  .closeout-cell {
    /* border-right: rgb(219, 200, 92) 5px solid; */
    background-color: #ffe28c;
  }

  .actual-cell {
    background-color: rgb(226, 228, 235);
    background: rgba(226, 228, 235);
  }

  .quoted-cell {
    background-color: #d1d7f1;
  }