@tailwind base;
@tailwind components;
@tailwind utilities;

// Flex Grid styles
.tradeScope-container {
    .tab-content {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        #tradeScope-tabpane-items.active {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .trade-scope-grid-items {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                overflow: auto;
                height: 0;
            }
        }

        #tradeScope-tabpane-itemsBudget.active {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .trade-budget-grid {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                overflow: auto;
                height: 0;
            }
        }

        #tradeScope-tabpane-alternates.active {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .trade-alternates-grid {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                overflow: auto;
                height: 0;
            }
        }

        #tradeScope-tabpane-clarifications.active {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .trade-clarifications-grid {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                overflow: auto;
                height: 0;
            }
        }
    }
}

.snapshot-modal-tabs {
    .tab-content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        & > .active {
            display: flex !important;
        }
    }
}

.expense-template-modal-flexgrid {
    .tab-content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        & > .active {
            display: flex !important;
        }
    }
}

.validation-error-highlight {
    background: #FF6666 !important;
}

// .wj-cell>div {
//     display: inline-block;
// }