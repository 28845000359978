@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');


/* Custom scroll bars */
/* div::-webkit-scrollbar-button {
  display: block; height: 10px; border-radius: 0px; background-color: red; 
}
div::-webkit-scrollbar-button:hover {
  background-color: red;
}
div::-webkit-scrollbar-track {
  background-color: #efefef;
} 
div::-webkit-scrollbar {
  width: 15px;
  height: 20px;
}

div::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}
div::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}
div::-webkit-scrollbar-track {
  background-color: #e4f8df;
}
div::-webkit-scrollbar-track:hover {
  background-color: #d5f1cd;
}
*/

/* Project Page Header */
.project-header-container {
  background: rgb(255, 251, 228);
}

.project-header-grid-container {
  /* background: rgb(255, 255, 255); */
  /* background-image: url('/media/error/bg-header1.png'); */
  background-image: url('../images/bg-header1.png');
  padding: 14px !important;
  border: 1px rgb(196, 200, 255, 0.5) solid;
  border-radius: 6px;
}

.project-header-value-lg {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 20px !important;
  font-weight: 900 !important;
  color: rgb(71, 83, 151, 0.85);
  padding-bottom: 10px;
}

.project-header-value-lg-fullscreen {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  color: rgb(71, 83, 151, 0.85);
}

.project-header-value-md {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgb(30, 33, 48);
}

.project-header-label-sm {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  color: rgb(71, 83, 151, 0.50);
}

.project-header-value-sm {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: rgb(56, 66, 121);
}

.project-header-status {
  text-align: end;
}

.project-header-status-message {
  width: 200px;
  height: 200px;
  background: rgba(222, 255, 222, 0.5);
  /* -webkit-animation: fadeinout 3s linear forwards;
  animation: fadeinout 3s linear forwards; */
}

.test-execution-header-grid-container {
  background: rgb(255, 243, 229);
  /* background-image: url('/media/error/bg-header1.png'); */
  padding: 14px !important;
  border: 1px rgb(196, 200, 255, 0.5) solid;
  border-radius: 6px;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: .9;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeinout {
  0% {
    opacity: .9;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* tabs */

.nav-link {
  border-left: solid 1px rgba(192, 192, 192, 0.1) !important;
  border-top: solid 1px rgba(192, 192, 192, 0.1) !important;
  border-right: solid 1px rgba(192, 192, 192, 0.1) !important;
  border-bottom: solid 1px rgba(192, 192, 192, 0.1) !important;
}

.nav-link.active {
  border-right: solid 1px rgba(192, 192, 192, 0.5) !important;
  border-top: solid 1px rgba(192, 192, 192, 0.5) !important;
  border-left: solid 1px rgba(192, 192, 192, 0.5) !important;
  border-bottom: solid 1px white !important;
}

.precalc-tabs-container {
  background-image: linear-gradient(#fefefe, #f2f2f2, white, #fdfdfd);
  padding: 10px 15px;
  border-radius: 5px;
}

.precalc-tab {
  background-color: #bfbfbf15;
  min-height: 500px;
}

.precalc-inner-header {
  background-image: linear-gradient(#f9f9f9, white, #fdfdfd);
}

.project-header-client-export {
  /* background-image: url('/media/logos/logo-4-sm.png'); */
  background-repeat: no-repeat;
  background-size: 150px;
  margin-bottom: 15px;
}

.project-header-client-md {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgb(30, 33, 48);
}

.project-header-client-sm {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: rgb(30, 33, 48);
}

.project-header-client-xs {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  color: rgb(30, 33, 48);
}

/* Precalc grid styles */

.hide-wijmo-grid {
  display: none;
}

.cell-monospace-right-selected-bid {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  background-color: #fffedd !important;
  justify-content: flex-end;
}

.cell-monospace-right-bottom-selected-bid {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  background-color: #fffedd !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cell-monospace-right-red {
  font-family: 'Open Sans', sans-serif;
  text-align: right;
  color: red;
  justify-content: flex-end;
}

.cell-monospace-right-bottom-red {
  font-family: 'Open Sans', sans-serif;
  text-align: right;
  color: red;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cell-monospace-right-bl {  
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  border-left: #000 solid 1px !important;
}

.cell-monospace-right-br {  
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  border-right: #000 solid 1px !important;
}

.cell-monospace-left-winningbid {
  font-family: 'Open Sans', sans-serif !important;
  text-align: left !important;
  background-color: #c5e9c2;
}

.cell-bold {
  font-weight: 600;
}

.winning-bidder :not(.wj-state-selected):not(.wj-state-multi-selected) {
  background-color: #c5e9c2;
  color: #000;
}

#bidtradeitems-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
}

#bidtradeitems-grid.wj-flexgrid .wj-header {
  padding: 4px;
}

#bidtradeitems-grid .wj-cell.wj-frozen:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected),
#bidtradeitems-grid .wj-cell.wj-frozen.wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
  background: rgb(236, 254, 255);
  font-weight: 500;
}

.grid-text-editor-textfield {
  /* background-color: white !important; */
  width: 100%;
  margin: 0 !important;
  border: 0 !important;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  line-height: normal !important;
}

.cell-bidsheetrow-bidder {
  font-family: 'Open Sans', sans-serif !important;
  padding: 0px !important;
}

.bid-sheet-edit-selected-bid {
  margin-bottom: 0px !important;
}

.bid-sheet-edit-selected-bid-checkbox {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 11px !important;
  padding-top: 4px;
  padding-left: 3px;
}

#bidtradeitems-grid .wj-cells .wj-cell.wj-state-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#bidtradeitems-grid .wj-cells .wj-cell.wj-state-multi-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#bidalternates-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

/* #bidalternates-grid.wj-flexgrid .wj-cells .wj-cell.wj-state-selected {
  color: black !important;
} */

#bidalternates-grid.wj-flexgrid .wj-header {
  padding: 4px;
}


#bidsummary-grid.wj-flexgrid .wj-cell {
  padding-top: 5px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 5px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#bidsummary-grid.wj-flexgrid .wj-header {
  display: grid;
  text-align: center !important;
  padding: 4px;
}

#bidsummary-grid .wj-cells .wj-cell.wj-state-selected {
  background: #0085c7 !important;
}

#bidsummary-grid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #00adbf !important;
}

#bidsummarytotal-grid.wj-flexgrid .wj-cell {
  padding-top: 5px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 5px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#bidsummarytotal-grid.wj-flexgrid .wj-header {
  padding: 4px;
}

#bidsummarytotal-grid .wj-cells .wj-cell.wj-state-selected {
  background: #0085c7 !important;
}

#bidsummarytotal-grid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #00adbf !important;
}

#bidtradeitemtemplates-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#bidtradeitemtemplates-grid.wj-flexgrid .wj-header {
  font-size: 13px;
  padding: 3px;
  padding-left: 5px;
  font-weight: 600 !important;
}

#bidtradeitemtemplates-grid.wj-flexgrid .wj-cell.wj-state-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#bidtradeitemtemplates-grid.wj-flexgrid .wj-cell.wj-state-multi-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

.bid-allowances-grid-header {
  font-weight: 900 !important;
  background-color: rgb(224, 231, 33) !important;
}

.bid-allowances-grid-subheader {
  font-weight: 600 !important;
  background-color: rgb(240, 240, 240) !important;
}

.bid-allowances-grid-total {
  font-weight: 600 !important;
  color: white !important;
  background-color: rgb(71, 10, 104) !important;
}

.bid-allowances-grid-normal {
  font-weight: 400 !important;
}

.trade-allowance-header {
  font-weight: 900 !important;
  background-color: rgb(224, 231, 33) !important;
}

.bidtradeitemtemplates-grid-section-header {
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #dfe7ed !important;
  font-weight: 600 !important;
  padding-top: 4px !important;
  padding-left: 10px !important;
  text-decoration: underline !important;
}

.bidtradeitemtemplates-grid-header {
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #eeeeee !important;
  font-weight: 600 !important;
  padding-top: 4px !important;
}

.bidtradeitemtemplates-grid-item {
  padding-top: 4px !important;
  font-weight: 100;
  /* padding-left: 20px !important; */
}

#checklistitems-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#checklistitems-grid.wj-flexgrid .wj-header {
  font-size: 13px;
  padding: 3px;
  padding-left: 5px;
  font-weight: 600 !important;
}

#checklistitems-grid.wj-flexgrid .wj-cell.wj-state-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#checklistitems-grid.wj-flexgrid .wj-cell.wj-state-multi-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

.checklistitems-grid-section-header {
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #dfe7ed !important;
  font-weight: 600 !important;
  padding-top: 4px !important;
  padding-left: 10px !important;
  text-decoration: underline !important;
}

.checklistitems-grid-header {
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #eeeeee !important;
  font-weight: 600 !important;
  padding-top: 4px !important;
  padding-left: 5px !important;
}

.checklistitems-grid-item {
  padding-top: 4px !important;
  font-weight: 100;
  padding-left: 10px !important;
  padding-right: 5px !important;
}


/* use flex display to center-align cells vertically */


#bid-sheet-grid.wj-flexgrid .wj-cell {
  padding-top: 3px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
  /* height: 42px; */
}

#bid-sheet-grid.wj-flexgrid .wj-cell.wj-header {
  text-align: center !important;
}

#bid-sheet-grid .wj-cells .wj-cell.wj-state-selected {
  background: #0085c7 !important;
}

#bid-sheet-grid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #00adbf !important;
}

#bid-sheet-grid .wj-flexgrid .wj-cell.wj-align-right {
  justify-content: flex-end;
}

#bid-sheet-grid .wj-flexgrid .wj-cell.wj-align-center {
  justify-content: center;
}

.bid-sheet-grid-empty-bid-cell {
  height: 42px;
}

.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup {
  display: flex;
  justify-content: center;
}

.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup.wj-align-right {
  justify-content: center;
}

/* total rows in various summary grids */
.precalc-total-row-highlight {
  background-color: rgb(224, 231, 33) !important;
  font-weight: 600 !important;
  padding-bottom: 3px !important;
}

/* subtotal rows in various summary grids */
.precalc-subtotal-row-highlight {
  background-color: rgb(146, 193, 233) !important;
  font-weight: 600 !important;
}

/* accent row in bid summary totals grid */
.precalc-accent4-row-highlight {
  background-color: rgb(248, 248, 248) !important;
  color: rgb(128, 128, 128);
}

.precalc-total-row {
  line-height: 16px;
}

.precalc-subtotal-alternatesVe-row-highlight {
  background-color: rgb(231, 231, 231) !important;
  border-bottom: solid 1px rgb(159 159 159) !important;
  font-weight: 600 !important;
}

.bidtrade-subtotal-row {
  font-weight: 600 !important;
}

.bidtrade-total-row {
  font-weight: 600 !important;
  border-top: solid 1px rgb(159 159 159) !important;
  border-bottom: solid 1px rgb(159 159 159) !important;
  background-color: rgb(224, 231, 33) !important;
}


/* Tables for Summary forms */
.summary-div {
  background-image: linear-gradient(white, #f2f2f2, white, #fdfdfd);
  padding: 20px 0px;
  border-radius: 5px;
}

.summary-table {
  background-image: linear-gradient(#fefefe, #f2f2f2, white, #fdfdfd);
  padding: 5px;
  border-radius: 5px;
}

.summary-table-short {
  background-image: linear-gradient(#f4f4f4, white, #fdfdfd);
  padding: 5px;
}

.summary-table-mid {
  background-image: linear-gradient(#f7f7f7, #f2f2f2, white, #fdfdfd);
  padding: 5px;
}

/* .summary-table td {
  border-top: 1px solid #ebedf2 ;
} */

.summary-table-row {
  /* border-top: 1px solid #3963ce !important; */
  border-top: 1px solid #ebedf2 !important;
}

.summary-subtotal-row {
  font-weight: 600 !important;
  border-top: 1px solid rgba(83, 81, 109, 0.400) !important;
  border-bottom: 2px solid rgba(83, 81, 109, 0.400) !important;
}

.summary-total-row {
  font-weight: 600 !important;
  border-top: 1px solid rgba(83, 81, 109, 0.500) !important;
  border-bottom: 3px solid rgba(83, 81, 109, 0.500) !important;
}

/* div for manpower container */
.hpm-content-div {
  background: rgba(255, 255, 255, 0.85);
}

/* .table th, .table td {
  padding-left: 10px !Important;
} */


.summary-header {
  font-weight: 800 !important;
  background: rgba(255, 255, 255, 0.50) !important;
  border-bottom: 2px solid #bbb !important;
  padding-top: 16px !important;
}

.summary-header-actual {
  font-weight: 800 !important;
  background: rgba(200, 202, 211, 0.50) !important;
  border-bottom: 2px solid #bbb !important;
  padding-top: 16px !important;
}

.summary-header-quoted {
  font-weight: 800 !important;
  background: rgba(209, 215, 241, 0.50) !important;
  border-bottom: 2px solid #bbb !important;
  padding-top: 16px !important;
}

.summary-cell-actual {
  background: rgba(200, 202, 211) !important;
  /* background-color: #c8cad3 !Important; */
}

.summary-cell-actual-disabled {
  background: rgba(200, 202, 211, 0.7) !important;
  /* background-color: #c8cad3 !Important; */
}

.summary-cell-quoted {
  background: rgba(209, 215, 241) !important;
  /* background-color: #d1d7f1 !Important; */
}

.summary-cell-quoted-disabled {
  background: rgba(209, 215, 241, 0.7) !important;
  /* background-color: #d1d7f1 !Important; */
}

.summary-inner {
  display: table;
  margin: 0 auto;
}

.summary-innercfe {
  display: table;
  margin: 0 auto;
}

.summary-info-cell {
  height: 2.75em;
  line-height: 2.75em;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.expense-header-container {
  background-image: linear-gradient(white, #f4f4f4, white, #fdfdfd);
  padding: 10px 0px 5px 0px;
  border-radius: 5px;
}

.grid-inputgroup-lg {
  width: 155px !important;
}

.grid-inputgroup {
  width: 125px !important;
}

.grid-select {
  border-color: #aaaaaa !Important;
  background-color: #ffe9bc !Important;
}


.grid-formcontrol {
  text-align: right;
  border-color: #aaaaaa !Important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !Important;
  padding: 0.65rem 1rem !Important;
  border-color: red !Important;
}

.excel-inputDate {
  background-color: #ffe9bc !Important;
  /* padding: 0.65rem 1rem; */
}

.summary-dense {
  margin-top: 0 !Important;
  margin-bottom: 0 !Important;
}

.summary-date {
  width: 131px !Important;
}

.input-group-prepend {
  margin-right: -1px;
  width: 34px;
}

.form-control-input {
  background-color: #ffe9bc !important;
}

/* styles for MUI form controls */

.MuiIconButton-root {
  padding: 0px !Important;
}

.textfield-error {
  background-color: #ffbebe !Important;
  border-radius: 4px;
}

.textfield-edit {
  background-color: #ffe9bc !Important;
  border-radius: 4px;
}

.textfield-edit-negative {
  background-color: #ffe9bc !Important;
  border-radius: 4px;
}

.textfield {
  background-color: #ffffff !Important;
  border-radius: 4px;
}

.textfield-edit-100 {
  background-color: #ffe9bc !Important;
  border-radius: 4px;
  width: 100% !Important;
}

.textfield-disabled-100 {
  border-radius: 4px;
  width: 100% !Important;
}

.input-money-edit {
  text-align: right;
  width: 76px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-money-edit-negative {
  text-align: right;
  width: 76px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
  color: red !Important;
}

.input-number-edit {
  text-align: right;
  width: 90px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-number-edit-negative {
  text-align: right;
  width: 90px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
  color: red !Important;
}

.input-pc {
  text-align: right;
  width: 74px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-pc-edit {
  text-align: right;
  width: 74px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-pc-edit-negative {
  text-align: right;
  width: 74px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
  color: red !Important;
}

.input-text-edit-100 {
  text-align: left;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-text-edit-right {
  text-align: right;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.text-number {
  font-family: 'Open Sans', sans-serif !Important;
  text-align: right;
}

.text-number-left {
  font-family: 'Open Sans', sans-serif !Important;
}

.select {
  min-width: 116px;
  display: 'flex';
}

.select-wide {
  min-width: 100%;
  display: 'flex';
  background-color: #fff;
}

.select-edit {
  min-width: 116px;
  background-color: #ffe9bc;
  /* full-width: true;  */
  display: 'flex';
  /* wrap: 'nowrap' */
}

.select-monospace {
  font-family: 'Open Sans', sans-serif !important;
  min-width: 116px;
  display: 'flex';
  background-color: #fff;
}

.menuitem-monospace {
  font-family: 'Open Sans', sans-serif !important;
  /* background-color: #fff; */
}

.label-disabled {
  color: #b9b9b9;
}

.autoNumeric-negative {
  color: red !important;
}

#popover-fieldlabel-help-normal {
  max-width: 300px;
}

#popover-fieldlabel-help-wide {
  max-width: 500px;
}

.help-popover-title-label {
  font-size: 15px !important;
  font-weight: 900px !important;
  padding-left: 8px;
  ;
}

.field-error {
  font-size: 11px !important;
  font-weight: 900px !important;
  color: red;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

/* Flexgrid */

.wj-flexgrid {
  /* height: 700px; */
  margin: 8px 0;
}

.wj-colheaders {
  background-color: #e1e1e1 !important;
  background: #e1e1e1 !important;
}

.flexgrid-col-header-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 11px;
  font-weight: 600;
}

.group-row {
  background-color: #e1e1e1;
  background: #e1e1e1;
}

.cell-monospace-right {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
}

.cell-monospace-center {
  font-family: 'Open Sans', sans-serif !important;
  text-align: center !important;
}

.cell-input-monospace-right {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  /* background-color: #ffe9bc !important; */
  background: #ffe9bc;
}

.cell-monospace-right-bottom {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cell-monospace-left {
  font-family: 'Open Sans', sans-serif !important;
  text-align: left !important;
}

.input-cell {
  font-family: 'Open Sans', sans-serif !important;
  background-color: #ffe9bc;
  background: #ffe9bc;
}

.actual-cell {
  font-family: 'Open Sans', sans-serif !important;
  background-color: rgb(226, 228, 235);
  background: rgba(226, 228, 235);
  text-align: right !important;
}

.quoted-cell {
  font-family: 'Open Sans', sans-serif !important;
  background-color: rgba(209, 215, 241);
  background: rgba(209, 215, 241);
  text-align: right !important;
}

/* orange total rows in various summary grids */
.subtotal-row-highlight {
  background-color: rgb(146, 193, 233) !important;
  font-weight: 600 !important;
}

/* FlexGrid */

/* Client Summary */

#client-summary-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif !important;
}

#client-summary-grid.wj-flexgrid .wj-header {
  text-align: center !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  font-size: 12px;
}

.client-summary-sectionheader {
  background-color: rgb(224, 231, 33) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-align: left !important;
}

.client-summary-sectionsubheader {
  font-weight: 700 !important;
  text-decoration: underline;
  text-align: left !important;
}

.client-summary-sectionsubtotal {
  font-weight: 700 !important;
  font-size: 11px !important;
  text-align: right;
}

.client-summary-sectiontotal {
  font-weight: 700 !important;
  text-align: right;
}

.client-summary-grandtotal {
  background-color: rgb(224, 231, 33) !important;
  font-weight: 700 !important;
  text-align: right;
}

/* Expense Template items */

#expense-template-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

.wj-listbox {
  max-height: 300px !important;
  /* height:240px; */
  font-size: 11px;
  font-family: 'Open Sans', sans-serif !important;
}

#expense-template-copy-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#expense-template-copy-grid.wj-flexgrid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #d1dfe5 !important;
  color: #000 !important;
}

#expense-template-copy-grid.wj-flexgrid .wj-cell input.wj-column-selector.wj-column-selector-group {
  transform: scale(1);
  margin-left: 2px;
  margin-right: 6px;
}

#manpower-cost-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#manpower-cost-grid.wj-flexgrid .wj-header {
  padding: 4px;
  padding-left: 5px;
  font-size: 12px;
  font-family: 'Poppins' !important;
}

#manpower-cost-grid.wj-flexgrid .wj-group {
  padding: 4px;
  font-size: 12px;
  font-family: 'Poppins' !important;
}

#manpower-cost-grid.wj-flexgrid .wj-group {
  padding: 4px;
  font-size: 12px;
  font-family: 'Poppins' !important;
}

#manpower-cost-grid.wj-flexgrid .wj-cell.wj-state-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#manpower-cost-grid.wj-flexgrid .wj-cell.wj-state-multi-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#manpower-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif !important;
}

#manpower-grid.wj-flexgrid .wj-header {
  padding: 4px;
  padding-left: 5px;
  font-size: 12px;
  font-family: 'Poppins' !important;
}

#manpower-grid.wj-flexgrid .wj-cell.wj-state-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

#manpower-grid.wj-flexgrid .wj-cell.wj-state-multi-selected {
  color: rgb(0, 0, 0);
  background-color: #bad5e5 !important;
}

.precon-cell {
  background-color: #a9d08e;
}

.total-cell {
  text-align: right;
}

.construction-cell {
  background-color: #9bc2e6;
  text-align: right;
}

.closeout-cell {
  background-color: #ffe28c;
  text-align: right;
}

.Precon {
  background-color: #a9d08e;
}

.Construction {
  background-color: #9bc2e6;
}

.Close-out {
  background-color: #ffe28c;
}

.header-left {
  text-align: left !important;
}

.header-left-bottom {
  text-align: left !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.header-right {
  text-align: right !important;
}

.header-center {
  text-align: center !important;
}

.header-center-bottom {
  text-align: center !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-weight: 500;
}

#expenses-grid.wj-flexgrid .wj-header {
  text-align: center !important;
  font-family: Poppins, Helvetica, sans-serif !important;
}

#expenses-grid .wj-glyph-up {
  display: none;
}

#expenses-grid .wj-sort-index {
  display: none;
}

#precon-grid .wj-glyph-up {
  display: none;
}

#manpower-cost-grid .wj-glyph-up {
  display: none;
}

#manpower-grid .wj-glyph-up {
  display: none;
}

/* Page Titles with Add button on the right */
.button-right {
  display: flex;
  justify-content: flex-end;
}

.add-button-title {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.add-button-manpower {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-top: -80px
}

.add-button {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.add-button-right {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.quotedpc-precon {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
}

.bidtstatus-label {
  width: 180px;
}

.bidtstatus-data {
  width: 500px;
  /* padding: 1rem !Important; */
}

.version-col-description {
  width: 180px;
}

.version-col-createdon {
  width: 180px;
}

.version-col-createdby {
  width: 180px;
}

.version-col-actions {
  width: 180px;
}

.animate-progress-bar {
  background-color: 'silver';
  height: 1px !important;
  width: 100%;
}

.bid-progress {
  margin-top: 6px;
}

.dashboardbids-progress {
  margin-top: 4px;
}

.trade-selector-menuitem {
  color: rgb(47, 47, 146)
}

.trade-selection-progress {
  height: 18px !important;
  width: 50px;
  margin-right: 8px;
}

#trade-selector .progress-bar {
  background-color: #8f96cc;
}

/* Modals */


.edit-md-dialog-modal {
  max-width: 800px !important;
  height: 700px !important;
}

.edit-md-modal {
  background-color: rgb(245, 245, 245);
}

.edit-mdlg-dialog-modal {
  max-width: 1100px !important;
  height: 700px !important;
}

.edit-lg-dialog-modal {
  max-width: 1100px !important;
}

.edit-lg-modal {
  background-color: rgb(245, 245, 245);
}

.edit-sm-dialog-modal {
  max-width: 600px !important;
  /* height: 700px !important; */
}

.edit-sm-modal {
  background-color: rgb(245, 245, 245);
}

.edit-sm-dialog-modal-note {
  max-width: 400px !important;
  height: 400px !important;
}

.edit-sm-modal-note {
  background-color: rgb(255, 250, 221);
  border: 1px solid #e5c25a;
}

.edit-sm-modal-second-modal {
  background-color: rgb(245, 245, 245);
  border: 1px rgb(175, 175, 175) solid;
  border-radius: 4px;
}

.edit-full-dialog-modal {
  max-width: 98% !important;
  /* height: 700px !important; */
}

.edit-full-modal {
  background-color: rgb(245, 245, 245);
}

.addresource-modal {
  background-color: #efefef;
}

.perdiem-dialog-modal {
  max-width: 430px !important;
}

.perdiem-modal {
  background-color: #efefef;
}

.address-dialog-modal {
  margin-top: 190px !important;
  max-width: 450px !important;
}

.address-modal {
  background-color: #e0eaff;
  border: rgb(184, 187, 212) 1px solid;
}

.changetasks-modal {
  max-width: 950px !important;
}

.addversion-modal {
  max-width: 400px !important;
  background-color: #efefef;
}

.editcsicode-modal {
  max-width: 600px !important;
  background-color: rgb(245, 245, 245);
}

.edituser-modal {
  max-width: 750px !important;
  background-color: rgb(245, 245, 245);
}

.addBidPermissions-modal {
  max-width: 600px !important;
  background-color: rgb(245, 245, 245);
}

.delete-confirm-modal-header {
  background-color: rgb(255, 236, 236);
  border: rgb(212, 184, 184) 1px solid;
}

.delete-confirm-modal-body {
  border: rgb(212, 184, 184) 1px solid;
  /* border: red 1px solid; */
}

.delete-confirm-modal-footer {
  border: rgb(212, 184, 184) 1px solid;
  /* border: red 1px solid; */
}

.edit-bidquote-modal {
  max-width: 700px !important;
  background-color: rgb(245, 245, 245);
}

.edit-tradescope-modal {
  max-width: 900px !important;
  background-color: rgb(245, 245, 245);
}

.footer-within-form {
  margin-top: 20px;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.small-summary-table-th {
  background-color: rgb(242, 242, 242);
}

.cell-link {
  font-weight: 600;
  text-decoration: underline;
  color: rgb(47, 47, 146);
  cursor: pointer;
}

.cell-link-secondary {
  font-weight: 500;
  text-decoration: underline;
  color: rgba(47, 47, 146, 0.685);
  cursor: pointer;
}

.cell-link-warning {
  font-weight: 600;
  text-decoration: underline;
  color: rgb(212, 0, 0);
  cursor: pointer;
}

.bid-sheet-cell-link {
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

/* bootstrap override for inputs in list header */

.input-group .input-group-text {
  border-color: #e2e5ec;
}

.checklist-item {
  width: 100%;
  border: 0px solid rgb(214, 228, 255);
  margin-bottom: 8px;
  background-color: rgb(240, 251, 255);
  padding: 0px;
  border-radius: 4px;
}

.tag-box {
  background-color: #f3f3f3 !important;
  border-radius: 4px;
  padding: 10px;
  max-width: 450px;
  border: 1px solid rgb(194, 214, 255);
}

.tag-select-new {
  width: 130px;
  height: 35px !important;
  margin-bottom: 5px;
  background-color: rgb(240, 251, 255) !important;
  border: 1px solid silver !important;
}

.tag-select-new-blank-input {
  width: 180px;
  height: 35px !important;
  margin-bottom: 5px;
  background-color: 'white';
  border: 1px solid silver !important;
}

.tag-input-prepend {
  width: 120px;
  height: 35px;
  margin-bottom: 5px;
}

.tag-input-prepend-text {
  width: 130px;
}

.tag-input {
  width: 180px;
  height: 35px !important;
  margin-bottom: 5px;
  background-color: 'white';
}

.tag-input-append {
  height: 35px;
  margin-bottom: 5px;
  cursor: pointer;
}

.tag-search-container {
  padding: 10px;
  width: 100%;
}

.tag-search-input-container {
  width: 450px;
}

.tag-search-item {
  font-size: 12px;
  border: 1px solid silver;
  background-color: rgb(240, 251, 255);
  border-radius: 4px;
  margin: 10px 10px 10px 0;
  display: inline-block;
  height: 35px;
  margin-bottom: 5px;
  padding: 7px 10px 0px 10px;
}

.tag-search-item-delete {
  margin-left: 8px;
}

.info-aside {
  font-weight: 400;
  background-color: rgb(238, 238, 238);
  border: 1px solid #d6d6d6;
  border-left: 4px solid #d6d6d6;
  padding: 1.2rem 1.2rem 0.5rem 1.2rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.help-aside {
  background-color: rgb(255, 245, 188);
  border: 1px solid #ffe564;
  border-left: 4px solid #ffe564;
  padding: 1.2rem 1.2rem 0.5rem 1.2rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.error-aside {
  color: brown;
  background-color: rgb(255, 225, 225);
  border: 1px solid #ff6464;
  border-left: 4px solid #ff6464;
  padding: 1.2rem 1.2rem 0 1.2rem;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}

.aside-caption {
  display: inline;
  padding-top: 4px;
  margin-left: 12px;
}

.loading-tile {
  height: 80px;
  padding: 10px;
}

.loading-message {
  display: inline;
  /* margin-left: 15px; */
  margin-bottom: 10px;
  color: rgb(72, 72, 72);
  font-weight: 400;
}

.bid-sheet-cell-highlight {
  font-weight: 600;
  text-decoration: underline;
  font-family: 'Open Sans', sans-serif !important;
  background-color: rgb(255, 245, 188);
}

.bid-sheet-cell-link-nobgcolor {
  font-weight: 400;
  /* text-decoration: underline; */
  cursor: pointer;
  font-family: 'Open Sans', sans-serif !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.manpower-cell-placeholder {
  background-color: rgb(255, 245, 188) !important;
}

.header-dropdown {
  color: #000;
  border: 1px solid silver !important;
  background-color: rgb(240, 251, 255) !important;
  border-radius: 4px !important;
  /* width: 300px !important; */
}

.subcontractor-contact-dropdown {
  color: #646c9a !important;
  width: 80% !important;
  /* border: 1px solid silver !important; */
  background-color: rgb(255, 255, 255) !important;
  border-radius: 4px !important;
  display: inline;
  padding: 0;
}

.subcontractor-contact-menuitem {
  color: #646c9a !important;
  background-color: rgb(255, 255, 255) !important;
  padding: 8px 8px 15px 8px !important;
  min-width: 200px !important;
  /* width: 250px !important; */
}

.subcontractor-contant-name {
  /* color: rgb(150, 150, 150); */
  font-size: 16px;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 600;
}

.subcontractor-contant-phone {
  color: rgb(150, 150, 150);
  font-size: 12px;
  text-align: left;
  padding-top: 5px;
  font-weight: 700;
}

.obvious-link {
  text-decoration: underline;
  font-weight: 500;
}

.card-list-item {
  height: 41px;
}

.card-list-link {
  height: 41px;
  cursor: pointer;
  font-weight: 500;
  height: 41px;
}

.card-list-item-label {
  font-weight: 400;
}

.card-list-item-value {
  font-weight: 500;
  text-align: right;
}

.sub-account-icon {
  font-size: 24px;
}

.grid-cell-lr-padding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.grid-mono-font {
  font-family: 'Open Sans', sans-serif !important;
  /* font-family: 'Roboto', sans-serif !important; */
  font-weight: 400 !important;
  font-size: 11px !important;
}

.grid-mono-font-first-col {
  padding-left: 16px !important;
  font-family: 'Open Sans', sans-serif !important;
  /* font-family: 'Roboto', sans-serif !important; */
  font-weight: 600 !important;
  font-size: 11px !important;
}

/* wijmo context menu */

/* .wj-listbox {
  height: 178 !important;
} */
.trade-grid-cell-excluded-from-calculation {
  color: #4400ff !important;
}

.ctx-menu {
  padding: 3px;
  min-width: 120px;
  /* background: #FFFBDD; */
  overflow: hidden;
  max-height: 600px !important;
}

.ctx-menu .wj-listbox-item {
  text-align: left;
  margin: 6px;
}

.ctx-menu-disabled {
  padding: 3px;
  min-width: 120px;
  /* background: #FFFBDD; */
  overflow: hidden;
}

.ctx-menu-disabled .wj-listbox-item {
  text-align: left;
  margin: 6px;
  color: #b9b9b9;
  cursor: default !important;
}

.ctx-menu-manpower {
  padding: 3px;
  min-width: 120px;
  background: #FFFBDD !important;
  overflow: hidden;
  height: 150px;
}

.ctx-menu-manpower .wj-listbox-item {
  text-align: left;
  margin: 6px;
}

.ctx-menu-manpower-disabled {
  padding: 3px;
  min-width: 120px;
  background: #FFFBDD !important;
  overflow: hidden;
}

.ctx-menu-manpower-disabled .wj-listbox-item {
  text-align: left;
  margin: 6px;
  color: #b9b9b9;
}

.ctx-menu-manpower-cost {
  padding: 3px;
  min-width: 120px;
  background: #FFFBDD !important;
  overflow: hidden;
  height: 206px;
}

.ctx-menu-manpower-cost .wj-listbox-item {
  text-align: left;
  margin: 6px;
}

.ctx-menu-manpower-cost-disabled {
  padding: 3px;
  min-width: 120px;
  background: #FFFBDD !important;
  overflow: hidden;
}

.ctx-menu-manpower-cost-disabled .wj-listbox-item {
  text-align: left;
  margin: 6px;
  color: #b9b9b9;
}

.ctx-menu-precon-cost {
  padding: 3px;
  min-width: 120px;
  background: #FFFBDD !important;
  overflow: hidden;
  height: 118px;
}

.ctx-menu-precon-cost .wj-listbox-item {
  text-align: left;
  margin: 6px;
}

.ctx-menu-precon-cost-disabled {
  padding: 3px;
  min-width: 120px;
  background: #FFFBDD !important;
  overflow: hidden;
}

.ctx-menu-precon-cost-disabled .wj-listbox-item {
  text-align: left;
  margin: 6px;
  color: #b9b9b9;
}

.bidTradeGrid-section-header {
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #dfe7ed !important;
  font-weight: 600 !important;
  padding-top: 4px !important;
  padding-left: 10px !important;
  text-decoration: underline !important;
}


.bidTradeGrid-item-header {
  background-color: #eeeeee !important;
  font-weight: 600 !important;
  padding-top: 4px !important;
  padding-left: 12px !important;
}

.bidTradeGrid-item {
  padding-top: 3px !important;
  font-weight: 100;
  padding-left: 7px !important;
  padding-right: 3px !important;
}

.bidTradeGrid-total {
  font-weight: 600 !important;
  border-top: 1px solid #000000;
  border-bottom: 2px solid #000000;
  background-color: rgb(224, 231, 33) !important;
  /* background-color: rgba(224, 231, 33, 0.5) !important; */
}


/* Edit Note triangle like Excel */
.triangle_tr {
  border-width: 0 10px 10px 0 !important;
  border-color: transparent red transparent transparent;
}

.wj-cell.wj-has-notes:after {
  position: absolute;
  content: '';
  width: 0;
  right: 0;
  top: -10px;
  border: 10px solid transparent;
  border-right: 10px solid red;
  opacity: .5;
}

.resource-grid-tooltip {
  font-family: 'Open Sans', sans-serif !important;
  background: #FFFBDD !important;
  border-color: #000;
  border-radius: 1px;
  font-size: 11px;
  color: black;
  font-weight: 500;
}



.bc-sync-spinner {
  margin-right: 8px;
  color: white;
}

.datagrid-csicodes-1 {
  background-color: #e2e2e2 !important;
  font-size: 13px;
}

.datagrid-csicodes-2 {
  background-color: #f5f5f5 !important;
}

.datagrid-csicodes-3 {
  background-color: #ffffff !important;
}

.csicodes-list-icon {
  margin-right: 5px;
  color: rgb(128, 128, 128);
}

.csi-code-grid-row-level1 {
  font-weight: 900;
  cursor: pointer;
  font-size: 13px;
}

.csi-code-grid-row-level2 {
  font-weight: 600;
  cursor: pointer;
}

.csi-code-grid-row-level3 {
  font-weight: 500;
  padding-left: 22px;
}

.csi-code-grid-row-level4 {
  font-weight: 500;
  padding-left: 22px;
}

.csi-code-grid-mono-font-level1 {
  padding-left: 6px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 900;
  font-size: 12px;
}

.csi-code-grid-mono-font-level2 {
  padding-left: 6px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.csi-code-grid-mono-font-level3 {
  padding-left: 6px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.csi-code-grid-mono-font-level4 {
  padding-left: 6px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.call-out-panel-neutral {
  padding-top: 8px;
  padding-right: 25px !important;
}

.call-out-panel-info {
  padding: 8px;
  padding-right: 15px !important;
  border: #afd7ee 1px solid;
  border-radius: 4px;
  background-color: #d7edff;
}

.form-control-status-blue {
  background-color: #caf0ff !important;
  border-color: #1294e0 !important;
}

.form-control-status-green {
  background-color: #caffe4 !important;
  border-color: #12e057 !important;
}

.form-control-status-yellow {
  background-color: #fffdca !important;
  border-color: #e0e012 !important;
}

.form-control-status-orange {
  background-color: #fff4ca !important;
  border-color: #e09412 !important;
}

.form-control-status-red {
  background-color: #ffcaca !important;
  border-color: #e01212 !important;
}


/* Client Summary */

#client-summary-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif !important;
}

#client-summary-grid.wj-flexgrid .wj-header {
  text-align: center !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  font-size: 12px;
}

.client-summary-sectionheader {
  background-color: rgb(224, 231, 33) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-align: left !important;
}

.client-summary-sectionsubheader {
  font-weight: 700 !important;
  text-decoration: underline;
  text-align: left !important;
}

.client-summary-sectionsubtotal {
  font-weight: 700 !important;
  font-size: 11px !important;
  text-align: right;
}

.client-summary-sectiontotal {
  font-weight: 700 !important;
  text-align: right;
}

.client-summary-grandtotal {
  background-color: rgb(224, 231, 33) !important;
  font-weight: 700 !important;
  text-align: right;
}

.form-control-link {
  text-decoration: underline;
  font-weight: 500 !important;
  cursor: pointer;
}

.contextmenu-url {
  color: rgb(0, 0, 0);
}

.checkbox-hpm {
  padding-left: 20px !important;
  padding-right: 8px !important;
}

/* Edit Note triangle like Excel */
.triangle_tr {
  border-width: 0 10px 10px 0 !important;
  border-color: transparent red transparent transparent;
}

.wj-cell.wj-has-notes:after {
  position: absolute;
  content: '';
  width: 0;
  right: 0;
  top: -10px;
  border: 10px solid transparent;
  border-right: 10px solid red;
  opacity: .5;
}

.resource-grid-tooltip {
  font-family: 'Open Sans', sans-serif !important;
  background: #FFFBDD !important;
  border-color: #000;
  border-radius: 1px;
  font-size: 11px;
  color: black;
  font-weight: 500;
}

.checkbox-item {
  padding-top: 4px;
  padding-left: 20px;
}

.project-grid-normal {
  padding: 20px;
  margin-bottom: 0 !important;
}

.project-grid-approval {
  background-color: #fdffe0;
  padding-top: 16px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-bottom: 12px !important;
  margin-bottom: 0 !important;
}

.formcontrol-edit {
  background-color: #ffe9bc !important;
  border-color: #c5c5c5 !important;
}

.projects-list-grid-container {
  font-family: 'Poppins', sans-serif !important;
  align-items: center;
}

.projects-list-grid-row-recent-project {
  background-color: #fffbf1 !important;
}

.projects-list-grid-item-name {
  line-height: 22px;
}

.projects-list-grid-item-state-bu {
  line-height: 22px;
  /* text-align: right; */
}

.projects-list-grid-item-dates {
  line-height: 18px;
}

.projects-list-grid-item-costs {
  line-height: 18px;
}

.projects-list-grid-item-cost-values {
  font-size: 1.05rem;
  font-weight: 500;
  color: #595d6e;
}

.projects-list-grid-item-date-value-blue {
  font-size: 0.95rem;
  font-weight: 500;
  color: #5578eb;
}

.projects-list-grid-item-date-value-red {
  font-size: 0.95rem;
  font-weight: 500;
  color: #fd397a;
}

.highlight-text {
  font-weight: 500;
}

.inline-icon {
  margin-left: 20px;
}

.col-header-center {
  text-align: center !important;
  font-size: 11px;
  /* height: 40px; */
}

.area-colgroup-header-center-1col {
  width: 80px !important;
  text-align: center !important;
  /* font-size: 11px; */
  /* height: 40px; */
}

.area-colgroup-header-center-2cols {
  width: 160px !important;
  text-align: center !important;
  /* font-size: 11px; */
  /* height: 40px; */
}

.area-colgroup-header-center-3cols {
  width: 250px !important;
  text-align: center !important;
  /* font-size: 11px; */
  /* height: 40px; */
}

.col-negative-value {
  color: red;
}

.placeholder-tradescope-grid {
  background-color: #FFF;
  height: 340px;
}

.fullscreen-icon {
  margin-left: 5px;
  margin-bottom: 1px;
  cursor: pointer;
  background-color: #fcfcfc;
  border: #c5e1fc 1px solid;
  border-radius: 3px;
}

.tradeScope-container {
  background: rgba(255, 255, 255, 0.85);
  padding: 10px 10px 0 10px;
  border: solid 1px rgb(196 200 255 / 50%);
  border-radius: 4px;
}

.setup-error-field-cell {
  background-color: #FFECE8;
}

.setup-action-bar {
  background-color: #f2f2f2;
  /* #f2f2f2 */
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #dedede;
}

.setup-action-bar-cell {
  padding-top: 20px !important;
  padding-left: 3px;
  height: 60px;
}

.setup-action-bar-message {
  padding-top: 10px;
  padding-left: 3px;
}

/* Trade scope grid items */

div.trade-scope-grid-items div.wj-cell:has(div.cell-input-wrapper) {
  overflow: visible;
}
div.trade-scope-grid-items div.wj-cell div:has(div.cell-input-wrapper) {
  width: inherit;
}

/* Trade scope totals table */

/* Applies to all header rows */
.trade-scope-grid-totals-header {
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

/* Applies to all value rows */
.trade-scope-grid-totals-value {
  text-align: right;
  color: rgb(0, 0, 0);
  font-weight: 800;
  padding-right: 5px !important;
}

/* background for header rows */
.trade-scope-grid-totals-prevBudget {
  background-color: rgb(32, 55, 100, 0.6);
}

.trade-scope-grid-totals-prevBudgetToFinalVariance {
  background-color: rgb(255, 0, 0, 0.6);
}

.trade-scope-grid-totals-myBudget {
  background-color: rgb(112, 48, 160, 0.6);
}

.trade-scope-grid-totals-myBudgetToFinalVariance {
  background-color: rgb(255, 0, 0, 0.6);
}

.trade-scope-grid-totals-finalTotal {
  background-color: rgb(84, 130, 53, 0.6);
}

.trade-scope-grid-totals-lowTotal {
  background-color: rgb(198, 89, 17, 0.6);
}

.trade-scope-grid-totals-pcSpreadBids {
  background-color: #c0d7c2;
}

.trade-scope-grid-totals-finalTotalPerArea {
  background-color: #c0d7c2;
}


/* background for value rows */
.trade-scope-grid-totals-value-prevBudget {
  background-color: rgb(32, 55, 100, 0.3);
}

.trade-scope-grid-totals-value-prevBudgetToFinalVariance {
  background-color: rgb(255, 0, 0, 0.3);
}

.trade-scope-grid-totals-value-myBudget {
  background-color: rgb(112, 48, 160, 0.2);
}

.trade-scope-grid-totals-value-myBudgetToFinalVariance {
  background-color: rgb(255, 0, 0, 0.2);
}

.trade-scope-grid-totals-value-finalTotal {
  background-color: rgb(84, 130, 53, 0.2);
}

.trade-scope-grid-totals-value-lowTotal {
  background-color: rgb(198, 89, 17, 0.2);
}

.trade-scope-grid-totals-value-pcSpreadBids {
  background-color: #c0d7c2;
}

.trade-scope-grid-totals-value-finalTotalPerArea {
  background-color: #c0d7c2;
}

.trade-scope-header-posted{
  color:#07c900;
}

.trade-scope-header-not-posted{
  color:#e01212;
}

.column-header-left {
  text-align: left !important;
}

.column-header-center {
  text-align: center !important;
}

.bidalternates-grid-alternate-row {
  font-weight: 600 !important;
}

.bidalternates-grid-ve-row {
  font-weight: 600 !important;
}

.bidalternates-grid-Proposed-row {
  background-color: rgb(112, 48, 160, 0.2);
}

.bidalternates-grid-Rejected-row {
  background-color: rgba(130, 58, 53, 0.2);
}

.bidalternates-grid-Accepted-row {
  background-color: rgb(84, 130, 53, 0.2);
}

.MuiDropzoneArea-text {
  font-size: 14px !important;
}

.heading-mui-icon {
  margin-left: 14px;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
}

.menuVersionLabel {
  padding-left: 30px;
}
.precon-list-card-header {
  background-color: #dfdfdf;
}

.precon-list-content {
  background-color: #f8f8f8;
}

.helpvideo-container {
  margin-bottom: 30px;
}

.helpvideo-card {
  width: 30rem;
  border: solid 1px #d6d6d6;
}


/* Override for bootstrap input validation */
.form-control.number-input {
  text-align: right;
}
.form-control.is-valid.number-input {
  background-position: left calc(0.375em + 0.3rem) center;
  padding-left: calc(1.5em + 1.2rem);
  padding-right: 0.9rem;
}


/* Add these classes to app2.css */

.bid-status-late {
  background-color: #92C0E9; 
}

/* .bid-status-undecided {
  background-color: #c9d61bb6; 
} */

/* .bid-status-invited {
  background-color: #e95177; 
} */

.bid-status-not-plans-and-specs {
  background-color: #DA291C; 
}

/* .bid-status-bidding {
  background-color: #5ac8ff; 
} */

/* .bid-status-not-bidding {
  background-color: #ffcc99; 
} */

/* .bid-status-bid-submitted {
  background-color: #cc99ff; 
} */

.bid-status-carried {
  background-color: #c5e9c200; 
}

.bid-status-incomplete {
  background-color: #e0e721; 
}
